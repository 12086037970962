<template>
  <div>
    <v-data-table :headers="headers" :items="members" sort-by="calories">
      <template v-slot:top>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div>
          <v-btn icon color="primary" rounded 
            @click="$router.push({name: 'EditMember', params: {id: item.id} })"
            :disabled="$route.name == 'EditMember' && $route.params.id == item.id"
          ><v-icon>mdi-pencil</v-icon></v-btn>
        </div>
      </template>
    </v-data-table>    
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import Api from '@/api/index'

export default {
  name: 'MemberList',
  data: () => ({
    headers: [
      { text: 'Nome', value: 'name', align: 'start'},
      { text: 'Email', value: 'email' },
      { text: 'Cargo', value: 'role.translated' },
      { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
    ],
    members: [],
  }),
  methods: {
    getData(){
      this.getUsers()
    },
    getUsers(){
      Api.User.index().then(r=>r.data).then(d => this.members = d)
    },
  },
  mounted () {
    this.getData()
  },
}
</script>

<style scoped>
</style>